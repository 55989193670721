<template>
  <div class="hk_page_routerview">
    <div class="hk_page_title hk_page_title_tabs">
      <div class="hk_page_title_left">
        <div class="title_txt">小红书订单</div>
        <el-tabs class="title_tabs" v-model="activeName">
          <el-tab-pane label="主订单" name="PARENT"></el-tab-pane>
          <el-tab-pane label="子订单-开发中" name="CHILDREN"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="hk_page_title_right">
        <el-dropdown size="small" disabled style="opacity:.6;" @command="handleCommand">
          <el-button type="primary" plain size="small">
            批量发货-开发中<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="VIRTUAL">无物流发货</el-dropdown-item>
            <el-dropdown-item command="PHYSICAL">实物发货</el-dropdown-item>

          </el-dropdown-menu>
        </el-dropdown>&nbsp;
        <el-button type="primary" size="small" plain @click="exportToLocal">导出</el-button>
      </div>
    </div>
    <div class="hk_page_wrap hk_page_wrap_tabs">
      <div class="hk-common-page">
        <div class="hk_search_box">

          <div class="hk_search_box_item">
            订单状态：
            <el-select v-model="searchOrderStatus" size="small" clearable placeholder="订单状态">
              <el-option label="全部" value="">全部</el-option>
              <el-option label="已完成" value="FINISH">已完成</el-option>
              <el-option label="已退费" value="REFUND">已退费</el-option>
              <el-option label="退费申请" value="REFUND_APPLY">退费申请</el-option>
              <el-option label="退费失败" value="REFUND_FAIL">退费失败</el-option>
            </el-select>

          </div>

          <div class="hk_search_box_item">
            付款时间：
            <el-date-picker v-model="searchTime" size="small" class="ipt-date-suffix" type="daterange" clearable range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :default-time="['00:00:00', '23:59:59']">
            </el-date-picker>
          </div>
          <div class="hk_search_box_item">
            <el-button type="primary" size="small" @click="searchEmployeeFun">搜索</el-button>
          </div>
        </div>
        <div class="hk_result_total_box">
          搜索共有 <span class="hk-font-highlight">{{ totalNum }}</span> 个订单，共计收款金额<span class="hk-font-highlight">￥{{ totalFeeMoney }}</span>
        </div>

        <div class="hk_custom_table">
          <el-table ref="myTable" :data="tableData" style="width: 100%">
            <!-- <el-table-column label="超时发货提醒" width="200px" fixed>
              <template slot-scope="scope">

                <div class="hk-font-highlight" v-if="scope.row.limit24 || scope.row.limit48">{{ scope.row.limit24 ||''}} <br>
                  {{ scope.row.limit48||'' }}</div>
                <span class="hk-font-red" v-if="scope.row.overSend || scope.row.sendUseTime">
                  {{ scope.row.overSend||'' }} <br>
                  {{ scope.row.sendUseTime||'' }}</span>
              </template>
            </el-table-column> -->
            <el-table-column label="订单号" width="100px" fixed>
              <template slot-scope="scope">
                <span>{{ scope.row.outOrderId }}</span>
              </template>
            </el-table-column>

            <el-table-column label="订单状态" width="100px">
              <template slot-scope="scope">
                <span class="hk-font-red" v-if="scope.row.orderStatus=='REFUND'">已退费</span>
                <span v-else>{{ scope.row.orderStatus }}</span>
              </template>
            </el-table-column>
            <el-table-column label="姓名" width="100px">
              <template slot-scope="scope">
                <span>{{ scope.row.stuName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="手机号" width="120px">
              <template slot-scope="scope">
                <span>{{scope.row.stuPhone||'-'}}</span>
              </template>
            </el-table-column>

            <el-table-column label="地址" width="200px">
              <template slot-scope="scope">
                <span> {{ scope.row.receiveDetailAddress || '-' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="达人标签" width="120px">
              <template slot-scope="scope">
                <span> {{ scope.row.id || '-' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="商品信息" width="450px">
              <template slot-scope="scope">
                <div class="red-product-show">
                  <div class="rps-item" v-for="(order,index) in scope.row.orderList" :key="order.id+'_'+index">
                    <img :src="order.productImg" alt="" class="rps-img">
                    <div class="rps-item-info">
                      <div class="info-name">{{order.productName}}</div>
                      <div class="info-txt">规格：{{order.expandInfo.spec || '--'}}
                        <div class="txt-right">X{{order.expandInfo.itemNum}}</div>
                      </div>
                      <div class="info-txt">条形码：{{order.barCode || '--'}}</div>
                      <div class="info-txt">商家编码：{{order.erpCode || '--'}}</div>
                    </div>
                  </div>

                </div>
              </template>
            </el-table-column>
            <el-table-column label="付款时间" width="160px">
              <template slot-scope="scope">
                <span>{{scope.row.payTime}}</span>
              </template>
            </el-table-column>
            <el-table-column label="应付金额" width="90px">
              <template slot-scope="scope">
                <span>{{scope.row.amount/ 100}}</span>
              </template>
            </el-table-column>
            <el-table-column label="优惠" width="90px">
              <!-- <template slot-scope="scope"> -->
              <span>--</span>
              <!-- </template> -->
            </el-table-column>
            <el-table-column label="实付金额" :min-width="100">
              <template slot-scope="scope">
                <span>{{scope.row.amount/ 100}}</span>
              </template>
            </el-table-column>
            <template v-if="activeName=='CHILDREN'">
              <el-table-column label="课程名称" width="120px">
                <template slot-scope="scope">
                  <span> {{ scope.row.id || '-' }}</span>
                </template>
              </el-table-column>
              <el-table-column label="课包" width="120px">
                <template slot-scope="scope">
                  <span> {{ scope.row.id || '-' }}</span>
                </template>
              </el-table-column>
              <el-table-column label="年级" width="120px">
                <template slot-scope="scope">
                  <span> {{ scope.row.id || '-' }}</span>
                </template>
              </el-table-column>
              <el-table-column label="学科" width="120px">
                <template slot-scope="scope">
                  <span> {{ scope.row.id || '-' }}</span>
                </template>
              </el-table-column>
            </template>
            <el-table-column label="售后金额" width="120px">
              <template slot-scope="scope">
                <span> 开发中{{ scope.row.id || '-' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="退款时间" width="120px">
              <template slot-scope="scope">
                <span> {{ scope.row.refundTime || '-' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="达人显示退费金额" width="150px">
              <template slot-scope="scope">
                <span>开发中 {{ scope.row.id || '-' }}</span>
              </template>
            </el-table-column>

            <!-- <el-table-column label="操作" fixed="right" width="125px">
            <template slot-scope="scope">
              <template v-if="scope.row.orderStatus=='PAID'|| scope.row.orderStatus=='REFUND_FAIL'">
                <span class="hk-table-option hk-table-option-first" @click="showRefundApplyFun(scope.row.stuId,scope.row.outTradeNo)">退费</span>
              </template>
              <template v-if="scope.row.orderStatus=='REFUND_APPLY' ||scope.row.orderStatus=='REFUND'">
                <span class="hk-table-option hk-table-option-first" @click="showRefundDetailFun(scope.row.stuId,scope.row.outTradeNo)">退费详情</span>
              </template>
            </template>
          </el-table-column> -->

            <div slot="empty">
              <p style="text-align:left;">没有订单信息哦！</p>
            </div>
          </el-table>
          <div class="hk_pagination" v-if="totalNum>0">
            <el-pagination @current-change="handleCurPageChange" @size-change="handleSizeChange" :current-page.sync="curPage" :page-size="pageSize" :page-sizes="[10,20, 50, 100]" layout="total,sizes,prev, pager, next" :total="totalNum">
            </el-pagination>
          </div>
        </div>
        <div class="hk_custom_scroll">
          <div ref="hkCustomScroll" class="hk-scroll" @scroll="handleScrollTop">
            <div class="hk-scroll-content" :style="'width:'+tableWidth+'px'"></div>
          </div>
        </div>
      </div>

    </div>

    <!-- 导入发货文件 ifVirtualDialog -->
    <el-dialog title="无物流发货" :visible.sync="ifVirtualDialog" :modal-append-to-body="false" width="480px" @close="hideVirtualDialog">
      <div class="export-dialog">
        <div class="export-main">
          <div class="export-upload" v-if="!fileVirtual">
            <div class="hk_upload_main">
              <input class="hk_ipt_file" id="J_course_file" type="file" @change="changeUploadExcel('J_course_file','course')" accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
              <div class="hk_upload_box">
                <div class="hk_upload-tag iconfont icon-jiahao"></div>
                <p class="hk_upload-txt">上传文件</p>
              </div>
            </div>
            &nbsp;&nbsp;
            <a href="https://oss.ke.live/prod/module/wuwuliufahuo.xlsx" class="d-template" target="_blank">下载模版</a>
          </div>
          <div class="export-remark" v-else>
            <el-form label-width="94px" size="small" :rules="dialogRules" ref="dialogForm" :model="dialogVirtualForm">
              <el-form-item label="导入备注：" prop="remark">
                <div class="hk-flex">
                  <el-input v-model="dialogVirtualForm.remark" placeholder="请输入"></el-input>
                  &nbsp;&nbsp;
                  <el-button type="primary" size="small" @click="exportFun('course','dialogForm')">导入</el-button>
                </div>
                <el-button type="primary" plain @click="fileVirtual=null"><i class="el-icon-upload el-icon--right"></i>重新上传</el-button>

              </el-form-item>

            </el-form>
          </div>
        </div>
        <div class="export-record">
          <h3 class="record-title">导入记录</h3>
          <div class="record-item-list">
            <div class="record-item" v-for="(item,idx) in recordList" :key="idx">{{item.staffName}} {{item.updateTime}}<span class="span">导入表格</span>{{item.remark}}</div>
          </div>
          <el-empty description="暂无导入记录" v-if="recordList.length<1"></el-empty>
        </div>

      </div>
    </el-dialog>

    <!-- 导入 ifPhysicalDialog -->
    <el-dialog title="实物发货" :visible.sync="ifPhysicalDialog" :modal-append-to-body="false" width="480px" @close="hidePhysicalDialog">
      <div class="export-dialog">
        <div class="export-main">
          <div class="export-upload" v-if="!filePhysical">
            <div class="hk_upload_main">
              <input class="hk_ipt_file" id="J_class_file" type="file" @change="changeUploadExcel('J_class_file','class')" accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
              <div class="hk_upload_box">
                <div class="hk_upload-tag iconfont icon-jiahao"></div>
                <p class="hk_upload-txt">上传文件</p>
              </div>
            </div>&nbsp;&nbsp;
            <a href="https://oss.ke.live/prod/module/shiwufahuo.xlsx" class="d-template" target="_blank">下载模版</a>

          </div>
          <div class="export-remark" v-else>
            <el-form label-width="94px" size="small" :rules="dialogRules" ref="classForm" :model="dialogPhysicalForm">
              <el-form-item label="导入备注：" prop="remark">
                <div class="hk-flex">
                  <el-input v-model="dialogPhysicalForm.remark" placeholder="请输入"></el-input>
                  &nbsp;&nbsp;
                  <el-button type="primary" size="small" @click="exportFun('class','classForm')">导入</el-button>
                </div>
                <el-button type="primary" plain @click="filePhysical=null"><i class="el-icon-upload el-icon--right"></i>重新上传</el-button>

              </el-form-item>

            </el-form>
          </div>
        </div>
        <div class="export-record">
          <h3 class="record-title">导入记录</h3>
          <div class="record-item-list">
            <div class="record-item" v-for="(item,idx) in recordList" :key="idx">{{item.staffName}} {{item.updateTime}}<span class="span">导入表格</span>{{item.remark}}</div>
          </div>
          <el-empty description="暂无导入记录" v-if="recordList.length<1"></el-empty>
        </div>

      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      tableWidth: 0, //table内容的实际宽度，赋值给自定义滚动条

      activeName: 'PARENT', //主订单、子订单
      searchOrderStatus: '', //搜索条件--订单状态
      searchTime: [], //搜索条件--付款开始时间-结束时间

      tableData: [], //列表中显示的数据(搜索后,只显示符合条件的数据)
      pageSize: 50, //每页50条数据
      curPage: 1, //当前第几页
      totalNum: 0, //总共几条数据

      totalFeeMoney: 0, //订单总额

      ifVirtualDialog: false, //导入 无实物发货
      ifPhysicalDialog: false, //实物发货
      fileVirtual: null,
      filePhysical: null,
      dialogVirtualForm: {
        remark: '',
      },
      dialogPhysicalForm: {
        remark: '',
      },
      dialogRules: {
        remark: [
          { required: true, message: '请输入', trigger: 'blur' }
        ]
      },
      recordList: []

    }
  },
  watch: {
    activeName(val, old) { //上下架状态
      console.log('tab切换搜索：' + val)
      if (val == 'CHILDREN') { //暂无接口
        this.tableData = []
      } else {
        this.searchEmployeeFun() //重新从第一页开始

      }

    }
  },
  mounted() {
    this.getDataListFun() //先隐藏代码
    this.getTotalFee()
  },
  methods: {
    //自定义滚动条的事件
    handleScrollTop(e) {
      let that = this;
      let tmpLeft = e.target.scrollLeft
      if (that.$refs["myTable"]) {
        that.tableDom = that.$refs["myTable"].bodyWrapper
        that.tableDom.scrollTo(tmpLeft, 0)

      }
    },

    //导出
    exportToLocal() {

      let that = this;

      that.requestDownloadGet({
        url: that.interfacePre() + '/manage/export/commonExport?exportType=ORDER_XIAO_HONG_SHU',
        success: function (result) {
          // console.log(result, '------导出')

          var elink = document.createElement('a');
          // 使用后端提供的文件名  命名
          const fileName = result.headers['content-disposition'].split('="')[1];
          elink.style.display = 'none';
          // 文件下载地址赋值
          elink.href = window.URL.createObjectURL(result.data);
          let str = decodeURIComponent(fileName);
          str = str.substring(0, str.length - 1);
          // 自定义文件名
          elink.download = str;
          // 调用a标签单击事件进行下载文件
          elink.click();

          that.$message('导出成功')

        },
        error: function (error) {
          //服务器异常
          that.$message.error('导出失败。' + error)
        }
      })
    },
    //顶部批量导入物流信息
    handleCommand(command) {
      let that = this;
      that.$message('功能开发中~')
      return false
      that.ifVirtualDialog = false
      that.ifPhysicalDialog = false

      if (command == 'VIRTUAL') { //无实物发货
        that.ifVirtualDialog = true
      } else { //实物
        that.ifPhysicalDialog = true
      }
      that.getRecordCourseUpload(command) //获取记录

    },

    //上传文件,若课程和辅导老师接口不一样，则需要分开写方法
    changeUploadExcel(eleId, type) {
      let that = this;
      let file = document.getElementById(eleId).files[0];

      console.log(file, '-----eleId--', eleId)

      if (file) {
        if (type == 'VIRTUAL') {
          that.fileVirtual = file

        } else {
          that.filePhysical = file
        }
      } else {
        that.$message.error('请先选择文件')
      }

    },

    //导入 先判断备注有没有填写
    exportFun(type, formName) {
      let that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.pageLoading = true;
          let url = '/manage/course/uploadCourseFile'
          let file = null;
          let paramRemark = ''

          if (type == 'VIRTUAL') { //无物流发货
            file = that.fileVirtual
            paramRemark = that.dialogVirtualForm.remark

          } else {
            url = '/manage/course/uploadClassFile'
            file = that.filePhysical
            paramRemark = that.dialogPhysicalForm.remark
          }
          let fd = new FormData();
          fd.append("file", file);
          fd.append("remark", paramRemark)
          var xhr = new XMLHttpRequest();
          xhr.open("POST", that.interfacePre() + url);
          // xhr.setRequestHeader('Content-Type',''); //提示 501 Current request is not a multipart request
          // xhr.setRequestHeader('Content-Type','multipart/form-data; boundary=--------------------------479840371711055579746182')
          // form-data 会提示跨越
          xhr.setRequestHeader("Authorization", localStorage.getItem('hkmanage_session'));
          xhr.onload = function () {
            let backRes = JSON.parse(xhr.responseText);
            console.log(backRes, '---back xhr')
            if (backRes.code == 200) {
              that.$message({
                message: '导入成功',
                type: 'success'
              });
              //然后要再次获取记录
              that.getRecordCourseUpload(type)
              that.getDataListFun()
            } else {
              that.$message({
                message: '导入失败，' + backRes.message,
                type: 'error'
              });
              console.log('导入失败。')
            }
            setTimeout(() => {
              that.pageLoading = false;
            }, 500)
          };
          xhr.send(fd);

        } else {
          console.log('未输入')
        }
      });
    },

    //获取上传记录   "EEO_DATA_VIRTUAL"--无实物 EEO_DATA_PHYSICAL--有实物发货
    getRecordCourseUpload(type) {
      let that = this;
      let params = {
        pageNum: 1,
        pageSize: 500
      }
      let ossPath = ''
      if (type == 'VIRTUAL') { //无物流
        ossPath = 'EEO_DATA_VIRTUAL'
      } else {
        ossPath = 'EEO_DATA_PHYSICAL'
      }
      params.ossPath = ossPath
      that.request({
        method: 'post',
        url: '/manage/ossFile/listRecord',
        param: params,
        success: function (res) {
          if (res.code == 200) {
            that.recordList = res.result.list || []

          } else {
            that.$message.error('获取上传记录失败--' + res.msg)
          }
        },
        error: function (error) {
          //服务器异常
          console.log(error);
          that.$message.error('获取上传记录失败');
        }
      })
    },

    hideVirtualDialog() {
      this.ifVirtualDialog = false
      this.fileVirtual = null

    },
    hidePhysicalDialog() {
      this.ifPhysicalDialog = false
      this.filePhysical = null

    },

    //搜索
    searchEmployeeFun() {
      this.curPage = 1
      this.tableData = []
      this.totalNum = 0
      this.getDataListFun()
      this.getTotalFee()
    },
    //获取数据列表
    getDataListFun() {
      let that = this;
      let param = {
        pn: that.curPage,
        ps: that.pageSize,
        orderType: 'XIAO_HONG_SHU'
      };

      if (that.searchOrderStatus) { //订单状态
        param.orderStatus = that.searchOrderStatus
      }

      if (that.searchTime && that.searchTime[0]) {
        param.queryStartTime = that.searchTime[0].getTime()
        param.queryEndTime = that.searchTime[1].getTime();
      }

      that.tableData = [] //先将数据清空，避免视图不及时更新
      that.request({
        method: 'post',
        url: '/manage/order/listParentOrder',
        param: param,
        success: function (result) {
          if (result.code == 200) {
            let data = result.result;
            that.tableData = data.list || [];
            that.totalNum = Number(data.total);
            //设置滚动
            setTimeout(() => {
              if (that.$refs["myTable"]) {
                that.tableWidth = that.$refs["myTable"].bodyWrapper.scrollWidth + 50 //没有fixed或者fixed=right,发现宽度少了
                that.$refs["myTable"].doLayout()
                that.tableDom = that.$refs["myTable"].bodyWrapper
                that.tableDom.addEventListener('scroll', () => {
                  let scrollLeft = that.tableDom.scrollLeft
                  // console.log('table scroll----', scrollLeft)
                  that.$refs.hkCustomScroll.scrollTo(scrollLeft, 0)
                })
              }
            }, 300)
          } else {
            console.log(result, '----not 200')
          }
        },
        error: function (error) {
          //服务器异常
          that.$message.error('获取订单列表失败。')
          console.log(error, '-----fenye 获取订单失败')
        }
      })
    },
    //分页
    handleCurPageChange(val) {
      console.log(`当前页: ${val}`);
      this.curPage = val
      this.getDataListFun();
    },
    //修改每页展示的记录个数
    handleSizeChange(val) {
      console.log(`每页显示: ${val} 条数据`);
      this.pageSize = val
      this.curPage = 1
      this.getDataListFun();
    },

    //获取订单总收款金额
    getTotalFee() {
      let that = this;
      let param = {};

      if (that.searchOrderStatus) { //订单状态
        param.orderStatus = that.searchOrderStatus
      }

      if (that.searchTime && that.searchTime[0]) {
        param.queryStartTime = that.searchTime[0].getTime()
        param.queryEndTime = that.searchTime[1].getTime();
      }
      param.orderType = 'XIAO_HONG_SHU';

      that.request({
        method: 'post',
        url: '/manage/order/countAmount',
        param: param,
        success: function (result) {
          if (result.code == 200) {
            that.totalFeeMoney = (result.result || 0) / 100
          } else {
            console.log(result, '----not 200')
          }
        },
        error: function (error) {
          //服务器异常
          that.$message.error('获取订单totalfee失败。')
          console.log(error, '-----totalfee失败')
        }
      })
    },


  }
}
</script>

<style lang="scss" scoped>
// 表格中的商品信息
.red-product-show {
  .rps-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }

    .rps-img {
      width: 91px;
      height: 91px;
    }
    .rps-item-info {
      flex: 1;
      padding-left: 8px;
      font-family: PingFangSC, PingFang SC;
      .info-title {
        font-weight: 600;
        font-size: 14px;
        color: #000;
        line-height: 22px;
        padding-bottom: 5px;
      }
      .info-txt {
        font-weight: 400;
        font-size: 12px;
        color: #606060;
        line-height: 20px;
        display: flex;

        .txt-right {
          width: 50px;
          padding-left: 20px;
          font-size: 12px;
          color: #999999;
          letter-spacing: 2px;
        }
      }
    }
  }
}

//导入弹窗
.export-dialog {
  .export-main {
    padding-bottom: 20px;
    .export-upload {
      display: flex;
      align-items: center;
      .d-template {
        color: #f08d00;
      }
    }
    .export-remark {
      display: flex;
      align-items: center;
      padding-top: 8px;
      .ipt-remark {
        width: 200px;
        margin-right: 6px;
      }
    }
  }
  .export-record {
    .record-title {
      font-weight: 600;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
      padding-bottom: 12px;
    }
    .record-item-list {
      max-height: 480px;
      overflow: auto;
    }
    .record-item {
      font-weight: 400;
      font-size: 14px;
      color: #f08d00;
      line-height: 22px;
      text-align: left;
      .span {
        color: rgba(0, 0, 0, 0.85);
        padding: 0 4px;
      }
    }
  }
}

//将el-table的横向滚动条给隐藏,只能写在每个页面，写在公共里面无效
.hk_custom_table {
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper {
    overflow-x: hidden !important;
  }
}
</style>